const Colors = {
  blue: "#0051a2",
  yellow: "#0051a2",
  yellow2: "#ffc107",
  green: "#00a236",
  red: "#ff6060",
  black: "#284e75",
  purple: "#6633ff",
  gray: "#aaa",
  lightText: "#5a7794",
};
export default Colors;
